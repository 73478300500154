import Enum from './enum'

class Role extends Enum {
    static MANAGE_EMPLOYEE = 10; // 社員管理

    static values() {
        return {
            [this.MANAGE_EMPLOYEE]: '社員管理',
        }
    }
}

export default Role;
