<template>
    <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary mb-3">
        <div class="container-fluid">
            <router-link class="navbar-brand" :to="{name: 'Home'}"><i class="bi bi-camera-fill"></i> Arrange</router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <div class="container-fluid">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'Schedule'}">スケジュール</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'UndecidedSchedule'}">日時未定予定</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'SchoolList'}">学校・イベント</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                管理
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="productDropdown">
                                <li>
                                    <router-link class="dropdown-item" :to="{name: 'EmployeeList'}">社員</router-link>
                                    <router-link class="dropdown-item" :to="{name: 'PartnerList'}">応援カメラマン</router-link>
                                    <router-link class="dropdown-item" :to="{name: 'Skill'}">スキル</router-link>
                                    <router-link class="dropdown-item" :to="{name: 'Car'}">社用車</router-link>
                                    <router-link class="dropdown-item" :to="{name: 'Equipment'}">備品</router-link>
                                    <router-link class="dropdown-item" :to="{name: 'PartnerPassword'}">応援カメラマン用パスワード</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-person-circle"></i> 鈴木一郎 さん
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="productDropdown">
                            <li>
                                <router-link class="dropdown-item" :to="{name: 'Home'}"><i class="bi bi-door-open"></i>ログアウト</router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <div class="container container-main">
        <router-view/>
    </div>

    <screen-loader v-if="loading > 0"></screen-loader>

    <message-dialog ref="message_dialog" :title="dialog.title" :message="dialog.message" :icon="dialog.icon"></message-dialog>
</template>

<script>
import ScreenLoader from '@/components/tools/ScreenLoader.vue'
import MessageDialog from '@/components/tools/MessageDialog.vue'

export default {
    name: 'App',
    components: {
        ScreenLoader,
        MessageDialog
    },
    provide() {
        // 参考 emit/props vs provide/inject
        // https://cloudsmith.co.jp/blog/frontend/2020/12/1656030.html
        return {
            startScreenLoading: this.startScreenLoading,
            endScreenLoading: this.endScreenLoading,
            quitScreenLoading: this.quitScreenLoading,
            showMessage: this.showMessage,
            showErrorMessage: this.showErrorMessage,
        }
    },
    data() {
        return {
            loading: 0,
            dialog: {
                title: null,
                message: null,
                icon: null
            },
        }
    },
    mounted() {
        window.addEventListener('show_message', (event) => {
            this.showMessage(event.detail.title, event.detail.message);
        });
        window.addEventListener('show_error_message', (event) => {
            this.showErrorMessage(event.detail.title, event.detail.message);
        });
    },
    methods: {
        signOut() {
            this.$store.commit('auth/signOut')
        },
        startScreenLoading() {
            this.loading++;
        },
        endScreenLoading() {
            this.loading--;
        },
        quitScreenLoading() {
            this.loading = 0;
        },
        showMessage(title, message) {
            this.dialog.title = title ?? '完了';
            this.dialog.message = message;
            this.dialog.icon = 'success';
            this.$refs.message_dialog.show();
        },
        showErrorMessage(title, message) {
            this.dialog.title = title ?? 'エラー';
            this.dialog.message = message;
            this.dialog.icon = 'error';
            this.$refs.message_dialog.show();
        }
    },
    computed: {
        user_name() {
            return this.$store.state.auth.user_name;
        }
    }
}
</script>

<style>
.container {
    background: #FFF;
    padding-top: 1em;
    padding-bottom: 1em;
}
</style>
