import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    /**
     * ダッシュボード
     */
    {
        path: '/',
        name: 'Home',
        component: () => import('../pages/PageHome.vue')
    },
    /**
     * A101:ログイン
     */
    {
        path: '/signin',
        name: 'Signin',
        meta: { for_guest: true },
        component: () => import('../pages/PageSignin.vue')
    },
    /**
     * A201:スケジュール一覧
     */
    {
        path: '/schedules',
        name: 'Schedule',
        component: () => import('../pages/PageSchedule.vue')
    },
    /**
     * A202:日時未定予定一覧
     */
    {
        path: '/undecided-schedules',
        name: 'UndecidedSchedule',
        component: () => import('../pages/PageUndecidedSchedule.vue')
    },
    /**
     * A203:日時未定予定アラート
     */
    {
        path: '/undecided-schedules/alert',
        name: 'AlertUndecidedSchedule',
        component: () => import('../pages/PageAlertUndecidedSchedule.vue')
    },
    /**
     * A301:学校一覧
     */
    {
        path: '/schools',
        name: 'SchoolList',
        component: () => import('../pages/PageSchoolList.vue')
    },
    /**
     * A302:学校登録
     */
    {
        path: '/schools/add',
        name: 'SchoolAdd',
        component: () => import('../pages/PageSchoolAdd.vue')
    },
    /**
     * A303:学校編集
     */
    {
        path: '/schools/:id/edit',
        name: 'SchoolEdit',
        component: () => import('../pages/PageSchoolEdit.vue')
    },
    /**
     * A401:学校別行事一覧
     */
    {
        path: '/schools/:id/events',
        name: 'Event',
        component: () => import('../pages/PageEvent.vue')
    },
    /**
     * A501:社員一覧
     */
    {
        path: '/employees',
        name: 'EmployeeList',
        component: () => import('../pages/PageEmployeeList.vue')
    },
    /**
     * A502:社員登録
     */
    {
        path: '/employees/add',
        name: 'EmployeeAdd',
        component: () => import('../pages/PageEmployeeAdd.vue')
    },
    /**
     * A503:社員編集
     */
    {
        path: '/employees/:id/edit',
        name: 'EmployeeEdit',
        component: () => import('../pages/PageEmployeeEdit.vue')
    },
    /**
     * A601:応援カメラマン一覧
     */
    {
        path: '/partners',
        name: 'PartnerList',
        component: () => import('../pages/PagePartnerList.vue')
    },
    /**
     * A602:応援カメラマン登録
     */
    {
        path: '/partners/add',
        name: 'PartnerAdd',
        component: () => import('../pages/PagePartnerAdd.vue')
    },
    /**
     * A603:応援カメラマン編集
     */
    {
        path: '/partners/:id/edit',
        name: 'PartnerEdit',
        component: () => import('../pages/PagePartnerEdit.vue')
    },
    /**
     * A701:スキル一覧
     */
    {
        path: '/skills',
        name: 'Skill',
        component: () => import('../pages/PageSkill.vue')
    },
    /**
     * A801:社用車一覧
     */
    {
        path: '/cars',
        name: 'Car',
        component: () => import('../pages/PageCar.vue')
    },
    /**
     * A901:備品一覧
     */
    {
        path: '/equipments',
        name: 'Equipment',
        component: () => import('../pages/PageEquipment.vue')
    },
    /**
     * A1001:応援カメラマン用パスワード管理
     */
    {
        path: '/password',
        name: 'PartnerPassword',
        component: () => import('../pages/PagePartnerPassword.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
/*
router.beforeEach((to, from, next) => {
    if (to.matched.every(record => record.meta.for_guest)) {
        //ログインの不要な画面
        next();

    } else {
        //ログインの必要な画面
        if (to.matched.some((record) => !record.meta.for_guest) && !store.state.auth.access_token) {
            //ログイン切れ
            next({ path: '/signin', query: { redirect: to.fullPath } });
        } else {
            if (store.state.auth.is_provisional_password === IsProvisionalPassword.YES) {
                //仮パスワードである
                if (to.matched.some(record => record.name === 'Password')) {
                    next();
                } else {
                    next({ path: '/password' });
                }

            } else {
                //権限なし
                for (let record of to.matched) {
                    if (record.meta && record.meta.gate) {
                        if (false === store.getters['auth/' + record.meta.gate]()) {
                            next({ path: '/error/403' });
                        }
                    }
                }
                next();
            }
        }
    }
});
*/
export default router
