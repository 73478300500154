import Role from "@/models/enums/role";
import IsProvisionalPassword from "@/models/enums/role";

export const auth = {
    namespaced: true,
    state: {
        user_name: null,
        account_type: null,
        roles: [],
        access_token: null,
        access_token_expired: null,
        access_token_issued: null,
        refresh_token: null,
        refresh_token_expired: null,
        refresh_token_issued: null,
        is_provisional_password: null,
        is_signed_in: false,

    },
    mutations: {
        //APIのコールバックでよぶには actions が必要なはずだが
        //普通に呼べている件
        setAccessToken (state, param) {
            state.user_name = param.user_name;
            state.account_type = param.account_type;
            state.roles = param.roles ? param.roles.map(val => parseInt(val, 10)) : [];
            state.access_token = param.access_token;
            state.access_token_expired = param.access_token_expired;
            state.access_token_issued = param.access_token_issued;
            state.refresh_token = param.refresh_token;
            state.refresh_token_expired = param.refresh_token_expired;
            state.refresh_token_issued = param.refresh_token_issued;
            state.is_provisional_password = param.is_provisional_password;
            state.is_signed_in = true;
        },
        setIsProvisionalPassword (state) {
            state.is_provisional_password = IsProvisionalPassword.NO;
        },
        signOut (state) {
            state.user_name = null;
            state.account_type = null;
            state.roles = [];
            state.access_token = null;
            state.access_token_expired = null;
            state.access_token_issued = null;
            state.refresh_token = null;
            state.refresh_token_expired = null;
            state.refresh_token_issued = null;
            state.is_provisional_password = null;
            state.is_signed_in = false;
        },
    },
    getters: {
        canManageSpec: (state) => () => {
            return (state.roles.indexOf(Role.MANAGE_SPEC) >= 0);
        },
        canManageUser: (state) => () => {
            return (state.roles.indexOf(Role.MANAGE_USER) >= 0);
        },
        canEditBasic: (state) => () => {
            return (state.roles.indexOf(Role.EDIT_BASIC) >= 0);
        },
    }
}
